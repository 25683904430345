import React, { useState } from "react";
import { Player } from "video-react";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import "./App.css";

function App() {
    const [passcode, setPasscode] = useState();
    const [authenticated, setAuthenticated] = useState(false);
    const [error, setError] = useState(false);
    const isMobile = useMediaQuery("(max-width:959px)");

    const DownloadButton = withStyles(() => ({
        root: {
            color: "#1e1e1e",
            backgroundColor: "#dadada",
            "&:hover": {
                backgroundColor: "#a9a9a9",
            },
        },
    }))(Button);

    return (
        <Container maxWidth="md">
            <div
                className="App"
                style={{
                    position: "relative",
                    height: "95vh",
                    margin: "auto",
                }}
            >
                {authenticated ? (
                    <div
                        className="grid"
                        style={{
                            margin: 0,
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            msTransform: "translate(-50%, -50%)",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <Card raised={true} style={{ background: "#1e1e1e" }}>
                            <h1
                                style={{
                                    fontFamily: "EB Garamond, serif",
                                    color: "#fafafa",
                                    textAlign: "center",
                                    fontSize: isMobile ? "3rem" : "6rem",
                                    borderBottom: " 1px white solid",
                                    width: isMobile ? "90%" : "80%",
                                    margin: isMobile
                                        ? "2rem auto"
                                        : "3rem auto",
                                }}
                            >
                                The Life of Bar
                            </h1>
                            <Player
                                playsInline
                                poster="/assets/poster.png"
                                src="https://www.cestrouge.com/media/the-life-of-bar.mp4"
                            />
                            {isMobile ? (
                                <></>
                            ) : (
                                <div
                                    style={{
                                        width: "30%",
                                        margin: "3rem auto",
                                        minWidth: "250px",
                                    }}
                                >
                                    <a
                                        href="/media/the-life-of-bar.mp4"
                                        download="the-life-of-bar"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <DownloadButton
                                            variant="contained"
                                            style={{ width: "100%" }}
                                            color="primary"
                                            size="large"
                                            startIcon={<CloudDownloadIcon />}
                                        >
                                            Download
                                        </DownloadButton>
                                    </a>
                                </div>
                            )}
                        </Card>
                    </div>
                ) : (
                    <div
                        className="grid"
                        style={{
                            margin: 0,
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            msTransform: "translate(-50%, -50%)",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        {error ? (
                            <div className="error-container">
                                <p style={{ textAlign: "center" }}>
                                    Incorrect Passcode
                                </p>
                            </div>
                        ) : (
                            <></>
                        )}
                        <form className="form login">
                            <div className="form__field">
                                <input
                                    id="login__password"
                                    value={passcode}
                                    onChange={(e) => {
                                        setPasscode(e.target.value);
                                    }}
                                    type="number"
                                    name="password"
                                    className="form__input"
                                    placeholder="Passcode"
                                    required
                                ></input>
                            </div>

                            <div className="form__field">
                                <button
                                    type="submit"
                                    placeholder="Access Content"
                                    onClick={() => {
                                        if (passcode == 10291029) {
                                            setAuthenticated(true);
                                        } else {
                                            setError(true);
                                        }
                                    }}
                                >
                                    Access Content
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </Container>
    );
}

export default App;
